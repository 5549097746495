import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_DATA_DASHBOARD } from "../config/queries";

export default function useMultipleEvents(addresses = []) {
  const [events, setEvents] = useState<any>();
  const [_getDataDashboard] = useLazyQuery(GET_DATA_DASHBOARD, {
    fetchPolicy: "network-only",
  });

  const refresh = async () => {
    if (!addresses?.length) {
      setEvents([]);
      return;
    }
    const results: any = await Promise.allSettled(
      addresses.map((_address) =>
        _getDataDashboard({ variables: { address: _address } }),
      ),
    );
    setEvents(
      results
        .map(
          (e) =>
            e?.value?.data?.getDataDashboard?.map((ev, i) => ({
              ...ev,
              sender: addresses?.[i],
            })) || [],
        )
        .flat(),
    );
  };

  useEffect(() => {
    refresh();
  }, [addresses]);

  return events;
}
