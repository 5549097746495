import { GET_CROSS_CHAIN_LIST } from "../config/queries";
import { useLazyQuery } from "@apollo/client";
import { contractAddresses } from "../config/contractAddressList";
import { TEST_CHAIN_ID } from "../config/constants";

export default function useCrossChainList() {
  const [_getCrossChainList, { loading, error, data }] = useLazyQuery(
    GET_CROSS_CHAIN_LIST,
    {
      fetchPolicy: "cache-first",
    },
  );

  const getCrossChainList = async () => {
    // const crossChainList = await _getCrossChainList();
    // return crossChainList?.data?.getCrossChainList?.chains || [];
    const allowedChains = Object.entries(contractAddresses)
      .filter(([_, addresses]) => addresses.OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS !== "" && addresses.OLPX_CROSSCHAIN_AGGREGATOR_ADDRESS !== undefined)
      .map(([chainId, _]) => ({
        chainId: parseInt(chainId)
      }));
    
    return allowedChains as unknown as [{ chainId: number }];
  };

  return { getCrossChainList, loading, error, data };
}
