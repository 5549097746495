import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import {
  ASSOCIATE_REFERRAL_B2B,
  CREATE_NICKNAME_B2B,
  GET_ADDRESS_BY_NICKNAME_B2B,
  GET_NICKNAME_BY_ADDRESS_B2B,
  GET_REFERRALS_BY_ADDRESS_B2B,
} from "../config/queries";
import { ChainContext } from "../contexts/ChainContext";
import { ToastrContext } from "../contexts/ToastrContext";
import { PUBLIC_URL, PARTNER_MAX_TIER, PARTNERS_CHAIN_ID } from "../config/constants";
import useMultipleEvents from "./useMultipleEvents";
import { ethers } from "ethers";
import useChainCalls from "./useChainCalls";
import { OLPX_PARTNERS_CLAIM_ABI } from "../abis/OLPX_PARTNERS_CLAIM_ABI";
import { contractAddresses } from "../config/contractAddressList";
import useContract from "./useContract";
import useFormatBigNumber from "./useFormatBigumber";
import useReferrals from "./useReferrals";

export default function usePartners() {

  const { associateReferral: associateReferralStaticV1 } = useReferrals({ disableAutomatic: true });
  const { formatToNumber } = useFormatBigNumber();
  const { connectedAccount, selectedChainId } = useContext(ChainContext);
  const { notify } = useContext(ToastrContext);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState<number | null>(null);
  
  // Get partners claim contract address
  const partnersClaimAddress = useMemo(() => {
    const address = contractAddresses[PARTNERS_CHAIN_ID]?.OLPX_PARTNERS_CLAIM_ADDRESS || '';
    // Only return non-empty addresses
    return address && address.length > 2 ? address : null;
  }, []);
  
  // Initialize contract for claim function
  const partnersClaimContract = useContract(
    partnersClaimAddress || "",
    OLPX_PARTNERS_CLAIM_ABI
  );

  // Mutations B2B
  const [_associateReferral] = useMutation(ASSOCIATE_REFERRAL_B2B);
  const [_createNickname, { loading: isCreatingNickname }] =
    useMutation(CREATE_NICKNAME_B2B);

  // Get address by nickname B2B
  const [_getAddressByNickname, { loading: isLoadingNickname }] = useLazyQuery(
    GET_ADDRESS_BY_NICKNAME_B2B,
  );

  // Get nickname by address B2B
  const {
    data: nicknameByAddress,
    loading: isLoading,
    refetch: refetchNicknameByAddress,
  } = useQuery(GET_NICKNAME_BY_ADDRESS_B2B, {
    variables: { address: connectedAccount },
    skip: !connectedAccount,
  });
  
  // Get referrals B2B
  const { data: _referrals, loading: isLoadingReferrals } = useQuery(
    GET_REFERRALS_BY_ADDRESS_B2B,
    {
      variables: { address: connectedAccount },
      skip: !connectedAccount,
    },
  );
  const referrals = _referrals?.getReferralsByAddressB2B || [];
  
  // Set if is partner
  const isPartner = useMemo(() => {
    return !!nicknameByAddress?.getNicknameByAddressB2B;
  }, [nicknameByAddress]);
  
  // Setup chain call to get claimable balance if the user is a partner
  const balanceCall = useMemo(() => {
    if (!connectedAccount || !isPartner || !partnersClaimAddress) return null;
    
    const iface = new ethers.Interface(OLPX_PARTNERS_CLAIM_ABI);
    return [
      {
        chainId: PARTNERS_CHAIN_ID,
        address: partnersClaimAddress,
        calldata: iface.encodeFunctionData('paymentTokenBalances', [connectedAccount]),
      },
      {
        chainId: PARTNERS_CHAIN_ID,
        address: partnersClaimAddress,
        calldata: iface.encodeFunctionData('paymentToken'),
      }
    ];
  }, [connectedAccount, isPartner, partnersClaimAddress]);
  
  // Execute the chain calls
  const chainCallResults = useChainCalls(balanceCall);
  
  // Update the claimable amount when balance changes
  useEffect(() => {
    if (chainCallResults && chainCallResults?.[0]?.success && chainCallResults?.[1]?.success) {
      try {
        const balance = chainCallResults[0].value;
        const paymentTokenAddress = chainCallResults[1].value;
        const balanceNumber = formatToNumber(balance, paymentTokenAddress, PARTNERS_CHAIN_ID);
        setClaimableAmount(balanceNumber);
      } catch (error) {
        console.error('Error parsing balance:', error);
        setClaimableAmount(null);
      }
    } else {
      setClaimableAmount(null);
    }
  }, [chainCallResults]);

  // Function to claim tokens
  const claimTokens = useCallback(async () => {
    if (!partnersClaimContract || !isPartner) {
      notify('Cannot claim rewards. Please check if you are connected to the correct network.', 'error');
      return false;
    }
    
    try {
      setIsClaimLoading(true);
      
      // Make sure user is on the correct chain
      if (selectedChainId !== PARTNERS_CHAIN_ID) {
        notify(`Please switch to the Polygon network to claim your rewards.`, 'warning');
        return false;
      }
      
      // Call the claim function
      const tx = await partnersClaimContract.claim();
      
      // Wait for transaction to be confirmed
      const receipt = await tx.wait();
      
      if (receipt && receipt.status === 1) {
        notify('Successfully claimed rewards!', 'success');
        // Reset claimable amount to zero after successful claim
        setClaimableAmount(0);
        return true;
      } else {
        notify('Transaction failed. Please try again.', 'error');
        return false;
      }
    } catch (error: any) {
      console.error('Error claiming tokens:', error);
      notify(error?.reason || error?.message || 'Failed to claim rewards. Please try again.', 'error');
      return false;
    } finally {
      setIsClaimLoading(false);
    }
  }, [partnersClaimContract, isPartner, selectedChainId, notify, connectedAccount]);

  // Referrals events
  let referralEvents = useMultipleEvents(referrals);
  const referralsWithEvents = useMemo(() => {
    if (!referrals?.length || !referralEvents?.length) return [];
    
    let _referrals = referrals.map((address) => {
      const events = referralEvents?.filter(
        (event) =>
          event?.sender?.toLowerCase()?.trim() ==
          address?.toLowerCase()?.trim(),
      );
      
      const volume = events?.reduce(
        (n, event) => n + parseFloat(ethers.formatEther(event?.tradedVolume)), 0
      );

      return {
        address,
        events,
        volume
      };
    });

    _referrals = _referrals
      .sort((a, b) => (b.volume || 0) - (a.volume || 0))
      .map((referral, index) => ({
        ...referral,
        position: index + 1,
        transactions: referral?.events?.length || 0
      }));

    return _referrals;
  }, [referrals, referralEvents]);

  // User events
  const userEvents = useMemo(() => {
    if (!referralsWithEvents?.length) return [];
    return referralsWithEvents.map(referral => ({
      address: referral.address,
      timestamp: referral.events?.length ? 
        Math.min(...referral.events.map(event => event.timestamp)) : 
        null
    })).sort((a, b) => 
      (a.timestamp || 0) - (b.timestamp || 0)
    ).filter(event => event.timestamp);
  }, [referralsWithEvents]);

  // Total referrals volume
  const totalReferralsVolume = useMemo(() => {
    if (!referralEvents?.length) return 0;
    return referralEvents.reduce((total, event) => {
      const tradedVolume = parseFloat(
        ethers.formatEther(event.tradedVolume.toString())
      );
      return total + tradedVolume;
    }, 0);
  }, [referralEvents]);

  // Tier
  const tier = useMemo(() => {
    if (totalReferralsVolume <= 50000) return 1;
    if (totalReferralsVolume <= 100000) return 2;
    if (totalReferralsVolume <= 200000) return 3;
    if (totalReferralsVolume <= 500000) return 4;
    return PARTNER_MAX_TIER;
  }, [totalReferralsVolume]);

  // Previous tier volume threshold
  const previousTierReferralsVolume = useMemo(() => {
    if (totalReferralsVolume <= 50000) return 0;
    if (totalReferralsVolume <= 100000) return 50000;
    if (totalReferralsVolume <= 200000) return 100000;
    if (totalReferralsVolume <= 500000) return 200000;
    return 500000;
  }, [totalReferralsVolume]);
  
  // Next tier volume threshold
  const nextTierReferralsVolume = useMemo(() => {
    if (totalReferralsVolume < 50000) return 50000;
    if (totalReferralsVolume < 100000) return 100000;
    if (totalReferralsVolume < 200000) return 200000;
    if (totalReferralsVolume < 500000) return 500000;
    return Infinity;
  }, [totalReferralsVolume]);

  // Actual commission rate
  const commissionRate = useMemo(() => {
    if (totalReferralsVolume <= 50000) return 10;
    if (totalReferralsVolume <= 100000) return 20;
    if (totalReferralsVolume <= 200000) return 30;
    if (totalReferralsVolume <= 500000) return 40;
    return 50;
  }, [totalReferralsVolume]);

  // Associate referral
  const associateReferral = async () => {
    const referralCode = localStorage.getItem("partner");
    if (!referralCode) return false;

    try {
      const addressByNicknameResponse = await _getAddressByNickname({
        variables: {
          nickname: referralCode,
        },
      });
      const referrerAddress =
        addressByNicknameResponse?.data?.getAddressByNicknameB2B;
      if (!!referrerAddress) {
        const response = await _associateReferral({
          variables: {
            referred: connectedAccount,
            referrer: referrerAddress,
          },
        });
        localStorage.removeItem("partner");
      } else {
        notify(
          `The nickname ${localStorage.getItem(`partner`)} is not valid`,
          "error",
        );
        localStorage.removeItem("partner");
      }
    } catch (err) {
      console.error("Error associating the referrer V2:", err);
    }

    try {
      await associateReferralStaticV1(referralCode);
    } catch (err) {
      console.error("Error associating the referrer V1:", err);
    }

    return true;
  };

  // Create nickname
  const createNickname = async (nickname: string) => {
    try {
      const response = await _createNickname({
        variables: {
          nickname: `${nickname}`,
          address: connectedAccount,
        },
      });
      if (!!response.data?.createNicknameB2B?.success) {
        notify("Referral nickname created successfully!", "success");
        return true;
      } else {
        notify(response.data?.createNicknameB2B?.message, "error");
      }
    } catch (err:any) {
      notify(err?.message || err?.msg || err, "error");
    }
    return false;
  };

  // Get address by nickname
  const getAddressByNickname = async (nickname: string) => {
    const response = await _getAddressByNickname({
      variables: { nickname: nickname },
    });
    return response?.data?.getAddressByNicknameB2B;
  };

  // Get partner from url params
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search);
      const partner = urlParams.get("partner") || urlParams.get("p");
      if (partner) {
        localStorage.setItem(`partner`, partner);
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(`partner`) && !!connectedAccount) {
      associateReferral();
    }
  }, [connectedAccount]);

  const uniqueReferrals = useMemo(() => {
    if (!referrals || !referrals.getReferralsByAddressB2B) return referrals;
    if (!Array.isArray(referrals.getReferralsByAddressB2B)) return referrals.getReferralsByAddressB2B;
    return [...new Set(referrals.getReferralsByAddressB2B)];
  }, [referrals]);

  return {
    createNickname,
    isCreatingNickname,
    nickname: nicknameByAddress?.getNicknameByAddressB2B || undefined,
    referralLink: `${PUBLIC_URL}?partner=${nicknameByAddress?.getNicknameByAddressB2B || ``}`,
    isLoadingNickname,
    refetchNicknameByAddress,
    getAddressByNickname,
    isPartner,
    referrals: uniqueReferrals,
    referralsWithEvents,
    isLoadingReferrals,
    loading: isLoading,
    events: referralEvents,
    userEvents,
    referralCount: uniqueReferrals?.length || 0,
    totalReferralsVolume,
    maxTier: PARTNER_MAX_TIER,
    tier,
    commissionRate,
    previousTierReferralsVolume,
    nextTierReferralsVolume,
    claimableAmount,
    claimTokens,
    isClaimLoading,
  };
}
