import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo } from "react";
import {
  ASSOCIATE_REFERRAL,
  CREATE_NICKNAME,
  GET_ADDRESS_BY_NICKNAME,
  GET_NICKNAME_BY_ADDRESS,
  GET_REFERRALS_BY_ADDRESS,
} from "../config/queries";
import { ChainContext } from "../contexts/ChainContext";
import { ToastrContext } from "../contexts/ToastrContext";
import { PUBLIC_URL } from "../config/constants";

export default function useReferrals({ disableAutomatic = false } = {}) {
  const { connectedAccount } = useContext(ChainContext);
  const { notify } = useContext(ToastrContext);
  const [_associateReferral] = useMutation(ASSOCIATE_REFERRAL);
  const [_createNickname, { loading: isCreatingNickname }] =
    useMutation(CREATE_NICKNAME);
  const [_getAddressByNickname, { loading: isLoadingNickname }] = useLazyQuery(
    GET_ADDRESS_BY_NICKNAME,
  );
  const {
    data: nicknameByAddress,
    loading: isLoadingNicknameByAddress,
    refetch: refetchNicknameByAddress,
  } = useQuery(GET_NICKNAME_BY_ADDRESS, {
    variables: { getNicknameByAddressAddress2: connectedAccount },
    skip: !connectedAccount || disableAutomatic,
  });
  const { data: referrals, loading: isLoadingReferrals } = useQuery(
    GET_REFERRALS_BY_ADDRESS,
    {
      variables: { address: connectedAccount },
      skip: !connectedAccount || disableAutomatic,
    },
  );

  const associateReferral = async (customReferralCode: string | null = null) => {
    try {
      console.log("associateReferral, try to associate referral", customReferralCode);
      const referralCode = customReferralCode || localStorage.getItem("ref");
      if (!referralCode) return false;
      
      const addressByNicknameResponse = await _getAddressByNickname({
        variables: {
          getAddressByNicknameNickname2: referralCode,
        },
      });
      const referrerAddress =
        addressByNicknameResponse?.data?.getAddressByNickname;
      if (!!referrerAddress) {
        await _associateReferral({
          variables: {
            referred: connectedAccount,
            referrer: referrerAddress,
          },
        });
        if (!customReferralCode) localStorage.removeItem("ref");
        return true;
      } else {
        notify(
          `The nickname ${referralCode} is not valid`,
          "error",
        );
        if (!customReferralCode) localStorage.removeItem("ref");
      }
    } catch (err) {
      notify("An error ocurred while associating the referrer", "error");
    }
    return false;
  };

  const createNickname = async (nickname) => {
    try {
      const response = await _createNickname({
        variables: {
          nickname: `${nickname}`,
          address: connectedAccount,
        },
      });
      if (!!response.data?.createNickname?.success) {
        notify("Referral nickname created successfully!", "success");
        return true;
      } else {
        notify(response.data?.createNickname?.message, "error");
      }
    } catch (err:any) {
      notify(err?.message || err?.msg || err, "error");
    }
    return false;
  };

  useEffect(() => {
    if (disableAutomatic || typeof window === 'undefined') return;
    
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref") || urlParams.get("via");
    if (ref) localStorage.setItem(`ref`, ref);
  }, [disableAutomatic]);

  useEffect(() => {
    if (disableAutomatic || !localStorage.getItem(`ref`) || !connectedAccount) return;
    
    associateReferral();
  }, [connectedAccount, disableAutomatic]);

  const uniqueReferrals = useMemo(() => {
    if (!referrals || !referrals.getReferralsByAddress) return referrals;
    if (!Array.isArray(referrals.getReferralsByAddress)) return referrals.getReferralsByAddress;
    return [...new Set(referrals.getReferralsByAddress)];
  }, [referrals]);

  return {
    createNickname,
    associateReferral,
    isCreatingNickname,
    nickname: nicknameByAddress?.getNicknameByAddress || undefined,
    referralLink: `${PUBLIC_URL}?ref=${nicknameByAddress?.getNicknameByAddress || ``}`,
    isLoadingNickname,
    refetchNicknameByAddress,
    referrals: uniqueReferrals,
    isLoadingReferrals,
  };
}
