exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bridge-tsx": () => import("./../../../src/pages/bridge.tsx" /* webpackChunkName: "component---src-pages-bridge-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-dca-tsx": () => import("./../../../src/pages/dca.tsx" /* webpackChunkName: "component---src-pages-dca-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nfts-about-tsx": () => import("./../../../src/pages/nfts/about.tsx" /* webpackChunkName: "component---src-pages-nfts-about-tsx" */),
  "component---src-pages-nfts-dashboard-tsx": () => import("./../../../src/pages/nfts/dashboard.tsx" /* webpackChunkName: "component---src-pages-nfts-dashboard-tsx" */),
  "component---src-pages-nfts-mint-tsx": () => import("./../../../src/pages/nfts/mint.tsx" /* webpackChunkName: "component---src-pages-nfts-mint-tsx" */),
  "component---src-pages-partners-onboarding-tsx": () => import("./../../../src/pages/partners/onboarding.tsx" /* webpackChunkName: "component---src-pages-partners-onboarding-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-swap-tsx": () => import("./../../../src/pages/swap.tsx" /* webpackChunkName: "component---src-pages-swap-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

