import { useContext } from "react";
import { ChainConfigContext } from "../contexts/ChainConfigContext";
import BigNumber from "bignumber.js";

export default function useFormatBigNumber() {
  const { getTokenByAddress } = useContext(ChainConfigContext);

  const formatToNumber = (
    bn: any,
    tokenAddress: string,
    chainId: number,
    numberDecimals: number | undefined = undefined,
  ) => {
    let shiftDecimals = 18;
    const bignumber = new BigNumber(bn?.toString() || bn);
    const token = getTokenByAddress(tokenAddress, chainId);
    if (token) shiftDecimals = token.decimals;
    return numberDecimals !== undefined
      ? parseFloat(
          bignumber
            .shiftedBy(-shiftDecimals)
            .toFixed(numberDecimals ? numberDecimals : shiftDecimals),
        )
      : bignumber.shiftedBy(-shiftDecimals).toNumber();
  };

  const formatToNumberSpecificDecimals = (
    bn: any,
    inputDecimals: number,
    outputDecimals: number | undefined = undefined,
  ) => {
    const bignumber = new BigNumber(bn?.toString() || bn);
    return parseFloat(
      bignumber
        .shiftedBy(-inputDecimals)
        .toFixed(outputDecimals ? outputDecimals : inputDecimals),
    );
  };

  const formatToBignumber = (
    number: number,
    tokenAddress: string,
    chainId: number,
  ) => {
    const roundedNumber = Math.floor(number * 1e6) / 1e6;
    const bignumber = new BigNumber(roundedNumber);
    const token = getTokenByAddress(tokenAddress, chainId);
    if (token) {
      return bignumber.shiftedBy(token.decimals).integerValue(BigNumber.ROUND_DOWN);
    }
    return undefined;
  };

  return { formatToNumber, formatToBignumber, formatToNumberSpecificDecimals };
}
